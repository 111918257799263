import Home from "./pages/home/Home";
import RoutesContainer from "./routes/Routes";


function App() {
  return (
    <>

    <RoutesContainer/>
      
    </>
  );
}

export default App;
